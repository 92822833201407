<template>
  <div class="unblock">
    <i @click="showModal" class="fas fa-unlock" title="Unlock"></i>
    <b-modal hide-footer body-class="p-0" ref="unblock-modal">
      <template v-slot:modal-header>
        <div class="header">
          <span>Are you sure you want to unblock this slot?</span>
        </div>
      </template>
      <form novalidate @submit.prevent="validateBeforeSubmit">
        <div class="cancelModalfooter footer">
          <span class="cancelModalNeedsupport">
            <a
              class="privacy-settings-support-text"
              href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
            > Need support? Click here!
            </a>
          </span>
          <button class="confirmButton" @click.prevent="sendReq">
            Unblock
          </button>
          <button class="confirmButton" @click="hideModal">
            Back
          </button>
        </div>
        <b-modal ref="congrat-modal" hide-footer>
          <div class="congrat">
            <p>CONGRATULATIONS!</p>
            <p>Your Meeting request has been successfully unlocked.</p>
          </div>
          <div style="height:7vh; width:100% ; border-top:1px solid #eee"></div>
        </b-modal>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    timeData: {
      type: Object,
      default: () => {},
    },
    time: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        reason: '',
      },
      error: null,
      myData: [],
    };
  },
  computed: {
    ...mapGetters({
      event: 'events/fetchEvent',
    }),
  },
  methods: {
    ...mapActions({
      meetingPlannerSlotUnblock: 'meetingPlanner/meetingPlannerSlotUnblock',
    }),
    showModal() {
      this.$refs['unblock-modal'].show();
    },
    async sendReq() {
      const isValid = await this.$validator.validateAll();
      const type = this.value.slot_type === 'blocked';
      if (isValid && type) {
        try {
          await this.meetingPlannerSlotUnblock(this.value.customer_meeting_planner_id);
          this.$refs['congrat-modal'].show();

          const data = {
            ...this.timeData,
            slot_type: null,
          };
          this.$emit('input', data);
        } catch (err) {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Something went wrong!',

          });
          console.log(err);
        }
      }
    },
    async validateBeforeSubmit() {
      await this.$validator.validate();
    },
    hideModal() {
      this.$refs['unblock-modal'].hide();
    },
  },
};
</script>
<style scoped src="./cancelMeetingModal.css"></style>
<style scoped src="./StylesForMobile.css"></style>
<style scoped>
#cancelModal .btn {
  font-size: 11px;
  border-radius: 0px;
  background-color: #BD9D5F;
}
#cancelModal .btn-secondary {
  color: #fff;
}
#cancelModal .btn-secondary:active {
  border: 1px solid #BD9D5F !important;
}
#cancelModal .btn-primary {
  background-color: #ffffff;
  color: #BD9D5F;
  border: 1px solid #BD9D5F;
}
#cancelModal .btn-secondary:hover {
  background-color: #ffffff !important;
  color: #BD9D5F !important;
}
#cancelModal .btn-primary:hover {
  background-color: #BD9D5F !important;
  color: #ffffff !important;
}
.unblock {
  padding-top: 6px;
}
.unblock>i{
  cursor: pointer;
  position: absolute;
  font-size: 11px;
  color: #BD9D5F;
  right: 0;
  top: 0;
  padding: 2px;
  transition-duration: 0.2s;
}
.unblock>i:hover {
  color: #fff;
  background-color: #BD9D5F;
}
@media (max-width: 768px) {
  .unblock > i {
    right: 8px;
    top: 12px;
    background-color: #f3f3f3;
    padding: 8px;
    border-radius: 50%;
  }
}
</style>
